export const faqData = [{
    question: "What Blockchain is $CEO on ?",
    answer: "$CEO on SOLANA Blockchain"
  },{
    question: "What's Token Contract Address ?",
    answer: "madHpjRn6bd8t78Rsy7NuSuNwWa2HU8ByPobZprHbHv"
  },{
    question: "What's the vision of Rekt CEO?",
    answer: "Rekt CEO is an initiative to create a brand with web3 community. We're solving What to do problem of crypto coins, where you can do so much with your $ceo coin."
  },{
    question: "What's in the future for Rekt CEO?",
    answer: "IRL clubhouse, IRL Parties, DAO based operations, Public chat for holders, Meme Coin Investment Funds, and more"
  }]